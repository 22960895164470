import React from "react";
import get from "lodash/get";
import { object, bool, number, string } from "prop-types";
import Carousel from "../../molecules/carousel";
import Headline from "../../atoms/headline";
import CarouselCard from "../../molecules/story-card-5";
import cx from "classnames";
import "./video-carousel.m.css";

const Slider = ({ collection, sectionTitle }) => {
  const getAssociatedMetaData = get(collection, ["associated-metadata"], {});

  const wrapperStyleName = cx({
    "bg-dark-container": getAssociatedMetaData["dark_background"],
    "bg-light-container": !getAssociatedMetaData["only_videos"]
  });
  const wrapperStyleNameContainer = cx("carousel-container", {
    "all-stories": !getAssociatedMetaData["only_videos"],
    "video-stories": getAssociatedMetaData["only_videos"]
  });

  const items = collection.items.filter(item => item.type === "story").slice(0, 12);
  const children = items.map((story, key) => {
    const storyObj = story.story ? story.story : story;
    return (
      <div key={key} className="wrapper" styleName="wrapper">
        <CarouselCard
          story={storyObj}
          headerLevel={4}
          headerType={3}
          imageType="image16x9"
          sectionTitle
          showPublishedTime
        />
      </div>
    );
  });

  return (
    <div styleName={wrapperStyleName}>
      <div styleName={wrapperStyleNameContainer} className="container">
        <Headline text={collection.name} headerType={8} headerLevel={2} />
        <Carousel
          carouselName={`carousel-${collection.id}`}
          options={{
            type: "carousel",
            perView: 3.75,
            gap: 24,
            breakpoints: {
              480: { perView: 1.2, peek: { before: 0, after: 80 } }
            },
            peek: { before: 0, after: 50 }
          }}
          totalItems={collection.items.length}
          renderCenterLeftControls={({ previousSlide, currentSlide }) => {
            return (
              currentSlide !== 0 && (
                <button onClick={previousSlide} className="slider-btn">
                  <div className="arrow left" />
                </button>
              )
            );
          }}
          renderCenterRightControls={({ nextSlide }) => (
            <button onClick={nextSlide} className="slider-btn">
              <div className="arrow right" />
            </button>
          )}
        >
          {children}
        </Carousel>
      </div>
    </div>
  );
};

Slider.propTypes = {
  collection: object,
  sectionTitle: bool,
  headerType: number,
  imageType: string,
  headerLevel: number
};

Slider.storyLimit = 12;

export default Slider;
