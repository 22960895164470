import React from "react";
import Headline from "../../atoms/headline";
import { string, number } from "prop-types";
import "./special-section-content.m.css";

const SpecialSectionContent = ({ collectionName = "", collectionSummary = "", headerLevel, headerType }) => (
  <div styleName="wrapper">
    <Headline text={collectionName} headerType={headerType} headerLevel={headerLevel} />
    <p styleName="summary">{collectionSummary}</p>
  </div>
);

SpecialSectionContent.propTypes = {
  collectionName: string,
  collectionSummary: string,
  headerLevel: number,
  headerType: number
};

export default SpecialSectionContent;
