import React from "react";
import { object } from "prop-types";
import get from "lodash/get";
import { ResponsiveImage } from "@quintype/components";
import Headline from "../../atoms/headline";
import VerticalContentWithImage from "../../molecules/vertical-content-with-image";

import "./special-section.m.css";

const SpecialSection = ({ collection }) => {
  const storyList = collection.items.filter(item => item.type === "story").slice(0, 3);
  const coverImageKey = get(collection, ["metadata", "cover-image", "cover-image-s3-key"], "");
  const coverImageMetadata = get(collection, ["metadata", "cover-image", "cover-image-metadata"], {});
  const coverImageCaption = get(collection, ["metadata", "cover-image", "caption"], "");
  const name = collection.name || "";
  const summary = collection.summary || "";

  return (
    <div styleName="wrapper">
      <figure>
        <ResponsiveImage
          slug={coverImageKey}
          metadata={coverImageMetadata}
          aspectRatio={[1440, 707]}
          defaultWidth="1440"
          widths={[360, 1440]}
          sizes="100%"
          imgParams={{ auto: ["format", "compress"] }}
          styleName="image"
          alt={coverImageCaption}
        />
      </figure>
      <div className="container" styleName="content-container">
        <div styleName="content-wrapper">
          <Headline text={name} headerType={10} headerLevel={1} />
          <span styleName="summary">{summary}</span>
        </div>
      </div>
      <div className="container" styleName="items-container">
        <div styleName="items-wrapper">
          {storyList.map((item, index) => (
            <VerticalContentWithImage key={index} story={item.story} />
          ))}
        </div>
      </div>
    </div>
  );
};

SpecialSection.propTypes = {
  collection: object.isRequired
};

SpecialSection.storyLimit = 12;

export default SpecialSection;
