import React from "react";
import { object } from "prop-types";
import get from "lodash/get";
import { Link, ResponsiveImage } from "@quintype/components";
import Headline from "../../atoms/headline";
import "./all-programs.m.css";

const programView = collection => {
  const coverImageKey = get(collection, ["metadata", "cover-image", "cover-image-s3-key"], "");
  const coverImageMetadata = get(collection, ["metadata", "cover-image", "cover-image-metadata"], {});
  const coverImageCaption = get(collection, ["metadata", "cover-image", "caption"], "");

  return (
    <Link href={`/videos/${collection.slug}`} key={collection.id}>
      <figure styleName="qt-image">
        <ResponsiveImage
          slug={coverImageKey}
          metadata={coverImageMetadata}
          aspectRatio={[4, 3]}
          defaultWidth="36"
          widths={[250, 480, 764]}
          sizes="( max-width: 500px ) 98%, ( max-width: 768px ) 48%, 23%"
          imgParams={{ auto: ["format", "compress"] }}
          styleName="image"
          alt={coverImageCaption}
        />
      </figure>
    </Link>
  );
};

const AllPrograms = ({ collection }) => {
  // Fetch only the collections
  const collectionList = collection.items.filter(item => item.type === "collection");

  return (
    <div styleName="container" className="container">
      <Headline text={collection.name} headerType={12} headerLevel={1} />

      <div styleName="programs">{collectionList.map(item => programView(item))}</div>
    </div>
  );
};

AllPrograms.propTypes = {
  collection: object
};

AllPrograms.storyLimit = 12;

export default AllPrograms;
