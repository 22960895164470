import React from "react";
import { string } from "prop-types";

const Video = ({ className = "" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width="54" height="37" viewBox="0 0 54 37">
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" stroke="#FFF" d="M.5.5h53v36H.5z" opacity="0.797" />
        <path fill="#8F0F01" d="M34 18.5l-12 6.167V12.333z" />
      </g>
    </svg>
  );
};

Video.propTypes = {
  className: string
};

export default Video;
