import React from "react";
import CardImage from "../../atoms/card-image/index";
import Headline from "../../atoms/headline/index";
import { object, number, string } from "prop-types";
import assetify from "@quintype/framework/assetify";
import Video from "../../atoms/icon/video.svg";
import Photo from "../../atoms/icon/photo.svg";
import "./story-card-3.m.css";
import { Link } from "@quintype/components";

const StoryCard3 = ({ story = {}, headerLevel, headerType, imageType = "image16x9" }) => {
  const storyHeadline = story["headline"];

  return (
    <Link href={`/${story.slug}`} styleName="wrapper" className="wrapper">
      <CardImage story={story} aspectRatio={[16, 9]} defaultWidth={330} imageType={imageType} />
      <div styleName="card-content" className="card-content">
        {story["story-template"] === "photo" && <img src={`${assetify(Photo)}`} alt="Photo" />}
        {story["story-template"] === "video" && <img src={`${assetify(Video)}`} alt="Video" />}
        <Headline text={storyHeadline} headerType={headerType} level={headerLevel} />
      </div>
    </Link>
  );
};
StoryCard3.propTypes = {
  story: object,
  headerLevel: number,
  headerType: number,
  imageType: string
};
export default StoryCard3;
