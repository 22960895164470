import React from "react";
import More from "../../atoms/more";
import Headline from "../../atoms/headline";
import OpinionCardWithHero from "../../molecules/opinion-card-with-hero";
import OpinionCardWithoutHero from "../../molecules/opinion-card-without-hero";
import "./opinion-row.m.css";
import { object } from "prop-types";
import DfpAd from "../../dfp-ad";

const getStoriesForRow = list =>
  list.map(
    (item, index) =>
      index === 0 ? (
        <div styleName="card" key={index}>
          <OpinionCardWithHero story={item.story} />
        </div>
      ) : (
        <div styleName="card" key={index}>
          <OpinionCardWithoutHero story={item.story} />
        </div>
      )
  );

const OpinionRow = ({ collection }) => {
  const items = collection.items.filter(item => item.type === "story").slice(0, 3);

  return (
    <div styleName="wrapper" className="container">
      <Headline text={collection.name} headerType={8} headerLevel={1} />
      <div styleName="content">
        {getStoriesForRow(items)}
        <div styleName="ad">
          <DfpAd adtype="mrec_2" />
        </div>
      </div>
      <More value="Read More" collection={collection} />
    </div>
  );
};

OpinionRow.propTypes = {
  collection: object
};

OpinionRow.storyLimit = 12;

export default OpinionRow;
