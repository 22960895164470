import React from "react";

import "./not-found.m.css";

function NotFoundPage() {
  return (
    <div styleName="wrapper">
      <h1 styleName="status">404</h1>
      <p styleName="status-message">Oops! There was a problem! Unfortunately, this page does not exist.</p>
      <div>
        <a href="/" styleName="button">
          Return to Home Page
        </a>
      </div>
    </div>
  );
}

export { NotFoundPage };
