// Update the carousel as and when required.
// Make sure to keep the props contract same as nuka carousel

import React, { Component } from "react";
import { arrayOf, func, element, object, number, string, bool } from "prop-types";
import Glide from "@glidejs/glide";

export default class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: this.props.options.startAt || 0
    };
    this.nextSlide = this.nextSlide.bind(this);
    this.previousSlide = this.previousSlide.bind(this);
    this.goToSlide = this.goToSlide.bind(this);
    this.renderGlideArrows = this.renderGlideArrows.bind(this);
  }

  renderGlideSlider = () => {
    if (this.props.children) {
      this.glide = new Glide("." + this.props.carouselName, this.props.options).mount();
      this.glide.on(["mount.after", "move.after"], () => {
        this.setState({ currentSlide: this.glide.index });
      });

      if (typeof this.props.afterSlide === "function") {
        this.glide.on("move.after", () => this.props.afterSlide(this.glide.index));
      }
    }
  };

  componentDidMount() {
    if (this.props.isDelay) {
      setTimeout(() => {
        this.renderGlideSlider();
      }, 1000);
    } else {
      this.renderGlideSlider();
    }
  }

  componentWillUnmount() {
    if (this.props.children && this.glide) {
      this.glide.destroy();
    }
  }

  renderChildren() {
    return this.props.children.map((child, index) => {
      return (
        <li key={index} className="glide__slide">
          {child}
        </li>
      );
    });
  }

  nextSlide() {
    this.glide.go(">");
  }

  previousSlide() {
    this.glide.go("<");
  }

  goToSlide(index) {
    this.glide.go("=" + index);
  }

  renderGlideArrows() {
    return (
      <div className="glide__arrows">
        {this.props.renderCenterLeftControls ? (
          <div className="center-left-controls slider-control-centerleft">
            {this.props.renderCenterLeftControls({
              previousSlide: this.previousSlide,
              nextSlide: this.nextSlide,
              goToSlide: this.goToSlide,
              totalSlides: this.props.children.length,
              currentSlide: this.state.currentSlide
            })}
          </div>
        ) : null}
        {this.props.renderCenterRightControls ? (
          <div className="center-right-controls slider-control-centerright">
            {this.props.renderCenterRightControls({
              previousSlide: this.previousSlide,
              nextSlide: this.nextSlide,
              goToSlide: this.goToSlide,
              totalSlides: this.props.children.length,
              currentSlide: this.state.currentSlide
            })}
          </div>
        ) : null}
      </div>
    );
  }

  render() {
    if (!this.props.children) {
      return null;
    }
    return (
      <div className={`glide ${this.props.carouselName} glide ${this.props.className}`}>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">{this.renderChildren()}</ul>
        </div>
        {this.props.totalItems > 1 && this.renderGlideArrows()}
      </div>
    );
  }
}

Carousel.displayName = "Carousel";

Carousel.defaultProps = {
  isDelay: false
};

Carousel.propTypes = {
  carouselName: string,
  children: arrayOf(element).isRequired,
  options: object,
  className: string,
  renderCenterLeftControls: func,
  renderCenterRightControls: func,
  renderBottomCenterControls: func,
  afterSlide: func,
  totalItems: number,
  timeout: number,
  isDelay: bool
};

const renderCenterLeftControls = ({ previousSlide }) => <button onClick={previousSlide}>Prev</button>;

renderCenterLeftControls.propTypes = {
  previousSlide: func
};

const renderCenterRightControls = ({ nextSlide }) => <button onClick={nextSlide}>Next</button>;

renderCenterRightControls.propTypes = {
  nextSlide: func
};

Carousel.defaultProps = {
  renderCenterLeftControls,
  renderCenterRightControls
};
