import React from "react";
import { object } from "prop-types";
import { Link } from "@quintype/components";

import CardImage from "../../atoms/card-image/index";
import Headline from "../../atoms/headline/index";
import Video from "../../icons/video";
import "./tv-program-video-card.m.css";

const TvProgramVideoCard = ({ story }) => {
  return (
    <Link href={`/${story.slug}`} styleName="wrapper">
      <div styleName="card-content" className="card-content">
        <CardImage story={story} aspectRatio={[16, 9]} defaultWidth={330} />
        <div styleName="video" className="icon-wrapper">
          <Video />
        </div>
      </div>
      <Headline text={story.headline} headerType={3} headerLevel={2} />
    </Link>
  );
};

TvProgramVideoCard.propTypes = {
  story: object
};

export default TvProgramVideoCard;
