import React from "react";
import "./card-image.m.css";
import { ResponsiveImage } from "@quintype/components";
import { object, array, string, number } from "prop-types";
import cx from "classnames";

const CardImage = ({ story = {}, aspectRatio, defaultWidth, imageType = "image16x9" }) => {
  const wrapperStyleName = cx(
    "qt-image-dynamic",
    { image16x9: imageType === "image16x9" },
    { image2x1: imageType === "image2x1" },
    { image4x3: imageType === "image4x3" },
    { image1x1: imageType === "image1x1" }
  );

  return (
    <figure styleName={wrapperStyleName} className={wrapperStyleName}>
      <ResponsiveImage
        slug={story["hero-image-s3-key"]}
        metadata={story["hero-image-metadata"]}
        aspectRatio={aspectRatio}
        defaultWidth={defaultWidth}
        widths={[250, 480, 764]}
        sizes="( max-width: 500px ) 98%, ( max-width: 768px ) 48%, 23%"
        imgParams={{ auto: ["format", "compress"] }}
        styleName="image"
        alt={story["hero-image-caption"] || story.headline}
      />
    </figure>
  );
};

CardImage.propTypes = {
  story: object,
  aspectRatio: array,
  defaultWidth: number,
  imageType: string
};

export default CardImage;
