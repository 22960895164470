import React from "react";
import "./jumbo-row.m.css";
import { object } from "prop-types";
import { ResponsiveImage } from "@quintype/components";
import SpecialSection from "../../organisms/special-section";
import get from "lodash/get";
import SpecialSectionContent from "../../molecules/special-section-content";
import cx from "classnames";
import DfpAd from "../../dfp-ad";

const JumboRow = ({ collection, metadata }) => {
  const coverImageKey = get(collection, ["metadata", "cover-image", "cover-image-s3-key"], "");
  const coverImageMetadata = get(collection, ["metadata", "cover-image", "cover-image-metadata"], {});
  const coverImageCaption = get(collection, ["metadata", "cover-image", "caption"], "");
  const contentPosition = (metadata && metadata["show_content_on_the_right"]) || "";
  const wrapperStyleName = cx("special-section", { "special-section-right": contentPosition });

  const imageWrapperStyleName = cx("image-wrapper", { "image-wrapper-right": contentPosition });

  return coverImageKey.length > 0 ? (
    <div styleName="wrapper">
      <figure styleName={imageWrapperStyleName}>
        <ResponsiveImage
          slug={coverImageKey}
          metadata={coverImageMetadata}
          aspectRatio={[1440, 707]}
          defaultWidth="1440"
          widths={[360, 1440]}
          sizes="100%"
          imgParams={{ auto: ["format", "compress"] }}
          styleName="image"
          alt={coverImageCaption}
        />
        <SpecialSectionContent
          collectionName={collection.name}
          headerType={9}
          headerLevel={1}
          contentPosition={contentPosition}
        />
      </figure>
      <div styleName={wrapperStyleName}>
        <SpecialSection collection={collection} contentPosition={contentPosition} />
      </div>
      <div styleName="ad-wrapper">
        <DfpAd adtype="Banner_1" />
      </div>
    </div>
  ) : null;
};

JumboRow.propTypes = {
  collection: object,
  metadata: object
};

JumboRow.storyLimit = 12;

export default JumboRow;
