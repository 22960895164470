import React from "react";
import { wrapCollectionLayout, EagerLoadImages } from "@quintype/components";
import CoverImageThreeColWithAd from "../rows/cover-image-three-col-with-ad";
import JumboRow from "../rows/jumbo-row";
import OpinionRow from "../rows/opinion-row";
import TwoRowThreeColGrid from "../rows/two-row-three-col-grid";
import SixRowWithAd from "../rows/six-row-with-ad";
import PhotoStory from "../rows/photo-story";
import Slider from "../rows/video-carousel";
import SpecialSection from "../rows/special-section";
import TvProgramCoverImageWithAd from "../molecules/tv-program-cover-image-with-ad";
import TvPrograms from "../rows/tv-programs";
import AllPrograms from "../rows/all-programs";

function wrapEager(f) {
  const wrappedComponent = function WrapEager(props) {
    if (props.index === 0) {
      return (
        <EagerLoadImages predicate={token => token === "above-fold"}>{React.createElement(f, props)}</EagerLoadImages>
      );
    } else {
      return React.createElement(f, props);
    }
  };

  if (f.storyLimit) {
    wrappedComponent.storyLimit = f.storyLimit;
  }
  return wrappedComponent;
}

export default {
  PhotoStory: wrapEager(wrapCollectionLayout(PhotoStory)),
  Slider: wrapEager(wrapCollectionLayout(Slider)),
  SixRowWithAd: wrapEager(wrapCollectionLayout(SixRowWithAd)),
  TwoRowThreeColGrid: wrapEager(wrapCollectionLayout(TwoRowThreeColGrid)),
  JumboRow: wrapEager(wrapCollectionLayout(JumboRow)),
  OpinionRow: wrapEager(wrapCollectionLayout(OpinionRow)),
  CoverImageThreeColWithAd: wrapEager(wrapCollectionLayout(CoverImageThreeColWithAd)),
  SpecialSection: wrapEager(wrapCollectionLayout(SpecialSection)),
  TvProgramCoverImageWithAd: wrapEager(wrapCollectionLayout(TvProgramCoverImageWithAd)),
  TvPrograms: wrapEager(wrapCollectionLayout(TvPrograms)),
  AllPrograms: wrapEager(wrapCollectionLayout(AllPrograms)),
  defaultTemplate: wrapEager(wrapCollectionLayout(CoverImageThreeColWithAd))
};
