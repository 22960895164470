import React from "react";
import { number, array, object, func, string } from "prop-types";
import get from "lodash/get";
import { removeDuplicateStories } from "./utils";

class LoadMoreStoriesManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pageNumber: 1,
      moreStories: [],
      noMoreStories: false
    };
  }

  getStories() {
    return this.props.stories.concat(this.state.moreStories);
  }

  loadMore(e) {
    e.preventDefault();
    if (this.state.loading) return;
    const pageNumber = this.state.pageNumber;
    this.setState({ loading: true, pageNumber: pageNumber + 1 }, () => {
      this.props.loadStories(pageNumber).then(stories => {
        this.setState({
          loading: false,
          moreStories: this.state.moreStories.concat(removeDuplicateStories(this.getStories(), stories)),
          noMoreStories: stories.length < this.props.numStoriesToLoad
        });
      });
    });
  }

  render() {
    return this.props.template(
      Object.assign({}, this.props, {
        stories: this.getStories(),
        onLoadMore: e => this.loadMore(e),
        loading: this.state.loading,
        noMoreStories: this.state.noMoreStories
      })
    );
  }
}

class LoadMoreAuthorStories extends React.Component {
  loadMoreStories(pageNumber) {
    const stories = get(this.props, ["data", "stories"], []);
    const params = Object.assign({}, this.props.params, {
      offset: this.props.numStoriesToLoad * (pageNumber - 1) + stories.length,
      limit: this.props.numStoriesToLoad || 10
    });

    return global
      .wretch(`/api/v1/authors/${this.props.authorID}/collection`)
      .query(params)
      .get()
      .json(response => (response.items || []).map(item => item.story));
  }

  render() {
    return React.createElement(
      LoadMoreStoriesManager,
      Object.assign({}, this.props.data, {
        template: this.props.template,
        loadStories: pageNumber => this.loadMoreStories(pageNumber),
        languageDirection: this.props.languageDirection,
        numStoriesToLoad: this.props.numStoriesToLoad || 10
      })
    );
  }
}

LoadMoreStoriesManager.propTypes = {
  stories: array,
  loadStories: func,
  numStoriesToLoad: number,
  template: func
};

LoadMoreAuthorStories.propTypes = {
  authorID: number,
  data: object,
  stories: array,
  params: object,
  numStoriesToLoad: number,
  template: func,
  languageDirection: string
};

export default LoadMoreAuthorStories;
