import React from "react";
import { object, number, string, bool } from "prop-types";
import { Link } from "@quintype/components";
import CardImage from "../../atoms/card-image";
import SectionName from "../../atoms/section-name";
import Headline from "../../atoms/headline";

import "./story-card-1.m.css";

const StoryCard1 = ({ story = {}, headerLevel, headerType, imageType, sectionTitle = true }) => {
  const sectionName = story.sections[0]["display-name"] || story.sections[0]["name"];
  const sectionSlug = story.sections[0]["slug"] || "";
  const storyHeadline = story["headline"];

  return (
    <div styleName="wrapper">
      <Link href={`/${story.slug}`}>
        <CardImage story={story} aspectRatio={[16, 9]} defaultWidth={330} imageType={imageType} />
      </Link>
      <div styleName="card-content">
        {sectionTitle && (
          <Link href={`/${sectionSlug}`}>
            <SectionName name={sectionName} />
          </Link>
        )}
        <Link href={`/${story.slug}`}>
          <Headline text={storyHeadline} headerType={headerType} headerLevel={headerLevel} />
        </Link>
      </div>
    </div>
  );
};

StoryCard1.propTypes = {
  story: object,
  headerLevel: number,
  headerType: number,
  imageType: string,
  sectionTitle: bool
};

export default StoryCard1;
