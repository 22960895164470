import React from "react";
import { object, func, number } from "prop-types";
import { LoadMoreStoriesBase } from "@quintype/components";
import CarouselCard from "../../molecules/story-card-5";
import More from "../../atoms/more";

import "./tag.m.css";

function TagPage({ data, config }) {
  const storyFields =
    "headline,subheadline,summary,sections,tags,author-name,author-id,authors,updated-at,last-published-at,published-at,updated-at,first-published-at,hero-image-metadata,hero-image-s3-key,story-content-id,slug,id,seo,story-template";

  return (
    <div styleName="tag-wrapper">
      <div>
        {data.stories.length ? (
          <h1 styleName="heading">
            Results For &#34;
            <span styleName="text-result">{decodeURI(data.tag.name)}</span> &#34;
          </h1>
        ) : (
          <h1 styleName="heading">
            No stories found for &#34;
            <span styleName="text-result">{data.tag.name}</span> &#34;
          </h1>
        )}
      </div>

      <LoadMoreStoriesBase
        template={MoreCollectionStories}
        fields={storyFields}
        params={{ "tag-slugs": data.tag.slug }}
        data={data}
        numStoriesToLoad={8}
      />
    </div>
  );
}

const getStory = stories => {
  return stories.map((story, index) => (
    <CarouselCard key={index} story={story} headerType={3} headerLevel={2} sectionTitle={true} />
  ));
};

function MoreCollectionStories({ stories, onLoadMore, noMoreStories }) {
  return (
    <div>
      <div styleName="stories">{stories.length ? getStory(stories) : null}</div>
      <div styleName="more-wrapper">
        {!noMoreStories && stories.length >= 8 ? <More type="load-more" onClick={onLoadMore} /> : null}
      </div>
    </div>
  );
}

TagPage.propTypes = {
  data: object,
  config: object
};

MoreCollectionStories.propTypes = {
  stories: object,
  onLoadMore: func,
  noMoreStories: number
};

export { TagPage };
