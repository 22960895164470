import React from "react";
import More from "../../atoms/more";
import SpecialSectionContent from "../../molecules/special-section-content/index";
import StoryCard4 from "../../molecules/story-card-4/index";
import { object } from "prop-types";
import "./special-section.m.css";

const getStoriesForRow = collection =>
  collection.items
    .filter(item => item.type === "story")
    .slice(0, 2)
    .map((item, index) => <StoryCard4 story={item.story} headerType={3} headerLevel={2} key={index} />);

const SpecialSection = ({ collection }) => {
  const collectionName = collection.name || "";
  const collectionSummary = collection.summary || "";

  return (
    <div styleName="wrapper" className="special-section-wrapper">
      <SpecialSectionContent
        collectionName={collectionName}
        collectionSummary={collectionSummary}
        headerType={9}
        headerLevel={1}
      />
      {getStoriesForRow(collection)}
      <More value="Read More" collection={collection} />
    </div>
  );
};

export default SpecialSection;

SpecialSection.propTypes = {
  collection: object
};
