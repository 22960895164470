import React from "react";
import { object, array, bool } from "prop-types";
import { connect } from "react-redux";
import LoadMoreSearchResults from "../../basic/load-more-search-results";
import "./search.m.css";

function SearchPageView({ data, breakingNews, breakingNewsLoaded }) {
  const headlineText = data.query || "";
  let headingStyleObj = "";

  // Remove extra space when breaking news present
  if (breakingNewsLoaded && breakingNews.length !== 0) {
    headingStyleObj = "heading-no-top-margin";
  }

  return (
    <div styleName="search-wrapper">
      <div>
        {data.total ? (
          <h1 styleName={`heading ${headingStyleObj}`}>
            Search For &#34;
            <span styleName="text-result">{headlineText}</span> &#34;
          </h1>
        ) : (
          <h1 styleName={`heading ${headingStyleObj}`}>
            No stories found for &#34;
            <span styleName="text-result">{data.query}</span> &#34;
          </h1>
        )}
      </div>

      <LoadMoreSearchResults data={data} itemsToLoad={10} />
    </div>
  );
}

function mapStateToProps(state) {
  const { breakingNews, breakingNewsLoaded } = state;

  return {
    breakingNews,
    breakingNewsLoaded
  };
}

SearchPageView.propTypes = {
  data: object,
  breakingNews: array,
  breakingNewsLoaded: bool
};

const SearchPage = connect(mapStateToProps)(SearchPageView);

export { SearchPage };
