import React from "react";
import { object } from "prop-types";
import { Link } from "@quintype/components";
import Headline from "../../atoms/headline";
import SectionName from "../../atoms/section-name";
import ResponsiveSourceImage from "../../atoms/responsive-source-image";
import AuthorWithDate from "../author-with-date";
import "./horizontal-content-with-image.m.css";

const HorizontalContentWithImage = ({ story = {} }) => {
  const sectionName = story.sections[0]["display-name"] || story.sections[0]["name"];
  const sectionSlug = story.sections[0]["slug"] || "";
  const storyHeadline = story["headline"];

  return (
    <div styleName="wrapper">
      <Link href={`/${story.slug}`}>
        <ResponsiveSourceImage story={story} />
      </Link>
      <div styleName="content-wrapper">
        <Link href={`/${sectionSlug}`}>
          <SectionName name={sectionName} />
        </Link>
        <Link href={`/${story.slug}`}>
          <Headline text={storyHeadline} headerType={7} headerLevel={1} />
          <AuthorWithDate story={story} isHeadline={false} />
        </Link>
      </div>
    </div>
  );
};

HorizontalContentWithImage.propTypes = {
  story: object
};

export default HorizontalContentWithImage;
