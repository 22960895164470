import React from "react";
import { object } from "prop-types";
import get from "lodash/get";
import { Link } from "@quintype/components";

import CardImage from "../../atoms/card-image";
import AuthorImage from "../../atoms/author-image";
import AuthorName from "../../atoms/author-name";
import Headline from "../../atoms/headline";
import "./opinion-card-with-hero.m.css";

const OpinionCardWithHero = ({ story = {} }) => {
  const authorDetails = {
    id: get(story, ["author-id"], ""),
    name: get(story, ["authors", 0, "name"], story["author-name"]),
    "avatar-url": get(story, ["authors", 0, "avatar-url"], ""),
    slug: get(story, ["authors", 0, "slug"], ""),
    "avatar-s3-key": get(story, ["authors", 0, "avatar-s3-key"])
  };

  return (
    <div styleName="wrapper">
      <Link href={`/${story.slug}`} styleName="image-wrapper">
        <CardImage story={story} defaultWidth={36} aspectRatio={[4, 3]} imageType="image4x3" />
      </Link>
      <Link href={`/author/${authorDetails.id}`} styleName="author-details">
        <AuthorImage author={authorDetails} />
        <AuthorName author={authorDetails} fontSize="large" />
      </Link>
      <Link href={`/${story.slug}`}>
        <Headline text={story.headline} headerType={5} headerLevel={2} />
      </Link>
    </div>
  );
};

OpinionCardWithHero.propTypes = {
  story: object
};

export default OpinionCardWithHero;
