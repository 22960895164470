import React from "react";
import { object, string } from "prop-types";
import Headline from "../../atoms/headline";
import More from "../../atoms/more";
import VerticalContentWithImage from "../../molecules/vertical-content-with-image";
import "./two-row-three-col-grid.m.css";
import { connect } from "react-redux";
import get from "lodash/get";
import DfpAd from "../../dfp-ad";

const TwoRowThreeColGridBase = ({ collection, pageType }) => {
  const list = collection.items.filter(item => item.type === "story").slice(0, 6);

  return (
    <div className="container">
      <div styleName="wrapper">
        {pageType !== "section-page" && <Headline text={collection.name} headerType={8} headerLevel={1} />}
        <div styleName="items-wrapper">
          {list.map((item, index) => (
            <VerticalContentWithImage key={index} story={item.story} />
          ))}
        </div>
        {collection.slug && pageType !== "section-page" && <More value="Read More" collection={collection} />}
      </div>
      <div styleName="ad-wrapper">
        <DfpAd adtype="Banner_2" />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    pageType: get(state, ["qt", "pageType"], "")
  };
}

const TwoRowThreeColGrid = connect(mapStateToProps)(TwoRowThreeColGridBase);

TwoRowThreeColGridBase.defaultProps = {
  collection: {}
};

TwoRowThreeColGridBase.propTypes = {
  collection: object,
  pageType: string
};

TwoRowThreeColGrid.storyLimit = 12;

export default TwoRowThreeColGrid;
