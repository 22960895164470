import React from "react";
import { object } from "prop-types";
import { ResponsiveImage } from "@quintype/components";

import "./author-image.m.css";

const AuthorImage = ({ author = {} }) => {
  const avatarURL = author["avatar-s3-key"];
  const authorAvatarUrl = author["avatar-url"];
  const name = author.name;
  const storyObj = {
    "hero-image-s3-key": avatarURL,
    "hero-image-metadata": "",
    "hero-image-caption": "Author",
    alt: name
  };

  let imageView = null;

  if (avatarURL) {
    imageView = <ResponsiveImage slug={storyObj["hero-image-s3-key"]} aspectRatio={[1, 1]} alt={name} />;
  } else if (authorAvatarUrl) {
    imageView = <img src={authorAvatarUrl} alt={name} />;
  }

  return (
    <div styleName="wrapper" className="author-image">
      {imageView}
    </div>
  );
};

AuthorImage.propTypes = {
  author: object
};

export default AuthorImage;
