import React from "react";
import { object, number } from "prop-types";
import Headline from "../../atoms/headline";
import ResponsiveSourceImage from "../../atoms/responsive-source-image";
import "./story-card-4.m.css";
import { Link } from "@quintype/components";

const StoryCard4 = ({ story = {}, headerLevel, headerType }) => {
  const storyHeadline = story["headline"];

  return (
    <Link href={`/${story.slug}`} styleName="wrapper">
      <ResponsiveSourceImage story={story} />
      <Headline text={storyHeadline} headerType={headerType} headerLevel={headerLevel} />
    </Link>
  );
};

StoryCard4.propTypes = {
  story: object,
  headerLevel: number,
  headerType: number
};
export default StoryCard4;
