import React from "react";
import { object, number, string, bool } from "prop-types";
import cx from "classnames";
import { Link } from "@quintype/components";
import assetify from "@quintype/framework/assetify";
import { getDateTimeFromUnixTimestamp } from "../../helper/utils";
import CardImage from "../../atoms/card-image/index";
import Headline from "../../atoms/headline/index";
import Video from "../../atoms/icon/video.svg";
import Photo from "../../atoms/icon/photo.svg";
import SectionName from "../../atoms/section-name";

import "./story-card-5.m.css";

const CarouselCard = ({ story = {}, headerLevel, headerType, imageType = "image16x9", sectionTitle }) => {
  const updatedOn = story["last-published-at"];
  const updatedDate = getDateTimeFromUnixTimestamp(updatedOn);
  const storyHeadline = story["headline"];
  const sectionName = story.sections && (story.sections[0]["display-name"] || story.sections[0].name);
  const sectionSlug = story.sections[0]["slug"] || "";
  const wrapperStyleName = cx("icon-wrapper", {
    video: story["story-template"] === "video",
    photo: story["story-template"] === "photo"
  });
  const isPhotoVideoStory = !!(story["story-template"] === "video" || story["story-template"] === "photo");
  let imageView = null;

  if (isPhotoVideoStory) {
    // For Photo or Video Story
    imageView = (
      <Link href={`/${story.slug}`} styleName="card-content">
        <CardImage story={story} aspectRatio={[16, 9]} defaultWidth={330} imageType={imageType} />
        <div styleName={wrapperStyleName} className="icon-wrapper">
          {story["story-template"] === "video" && <img src={`${assetify(Video)}`} alt="Video" />}
          {story["story-template"] === "photo" && <img src={`${assetify(Photo)}`} alt="Photo" />}
        </div>
      </Link>
    );
  } else {
    // For Normal Story
    imageView = (
      <Link href={`/${story.slug}`}>
        <CardImage story={story} aspectRatio={[16, 9]} defaultWidth={330} imageType={imageType} />
      </Link>
    );
  }

  return (
    <div styleName="wrapper" className="wrapper">
      {imageView}
      {sectionTitle && (
        <Link href={`/${sectionSlug}`}>
          <SectionName name={sectionName} />
        </Link>
      )}
      <Link href={`/${story.slug}`}>
        <time styleName="date" dateTime={updatedDate} className="date">
          {updatedDate}
        </time>
        <Headline text={storyHeadline} headerType={headerType} headerLevel={headerLevel} />
      </Link>
    </div>
  );
};

CarouselCard.propTypes = {
  story: object,
  headerLevel: number,
  headerType: number,
  imageType: string,
  sectionTitle: bool
};

export default CarouselCard;
