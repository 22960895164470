import React from "react";
import { object, bool, array, func } from "prop-types";
import { LoadMoreCollectionStories } from "@quintype/components";
import More from "../../atoms/more";
import TvProgramVideoCard from "../../molecules/tv-program-video-card";
import TvProgramCoverImageWithAd from "../../molecules/tv-program-cover-image-with-ad";
import "./tv-programs.m.css";

const TvPrograms = ({ collection }) => {
  const [firstStory, ...restOftheStories] = collection.items.filter(
    item => item.type === "story" && item.story["story-template"] === "video"
  );
  const numStoriesToLoad = 8;

  // Return back if 1st story itself doesn't exist
  if (!firstStory) return null;

  return (
    <div>
      <TvProgramCoverImageWithAd collection={collection} />

      <div styleName="container" className="container">
        {restOftheStories && restOftheStories.length ? (
          <LoadMoreCollectionStories
            template={e => MoreCollectionStories(e, numStoriesToLoad)}
            collectionSlug={collection.slug}
            data={{ stories: [...restOftheStories.map(({ story }) => story).slice(0, numStoriesToLoad)] }}
            numStoriesToLoad={numStoriesToLoad}
            params={{ "item-type": "story" }}
          />
        ) : null}
      </div>
    </div>
  );
};

function MoreCollectionStories({ stories, onLoadMore, noMoreStories }, numStoriesToLoad) {
  return (
    <div styleName="wrapper">
      <div styleName="stories">
        {stories.map((item, index) => (
          <TvProgramVideoCard key={index} story={item} />
        ))}
      </div>

      {numStoriesToLoad <= stories.length &&
        !noMoreStories && (
          <div styleName="more-wrapper">
            <More type="load-more" onClick={onLoadMore} />
          </div>
        )}
    </div>
  );
}

MoreCollectionStories.propTypes = {
  stories: array,
  onLoadMore: func,
  noMoreStories: bool
};

TvPrograms.propTypes = {
  collection: object.isRequired
};

TvPrograms.storyLimit = 12;

export default TvPrograms;
