import React from "react";
import { object, string, func } from "prop-types";
import assetify from "@quintype/framework/assetify";
import { ResponsiveImage } from "@quintype/components";

import More from "../../atoms/more";
import Headline from "../../atoms/headline";
import CarouselCard from "../../molecules/story-card-5";
import TwitterIcon from "../../atoms/icon/twitter";
import authorDefault from "./author_default.svg";
import LoadMoreAuthorStories from "../../basic/load-more-author";

import "./author.m.css";

const AuthorPage = ({ data, config }) => {
  const author = data.author;
  const avatarURL = author["avatar-s3-key"];
  const authorAvatarUrl = author["avatar-url"];
  const name = author.name;
  const storyObj = {
    "hero-image-s3-key": avatarURL,
    "hero-image-metadata": "",
    "hero-image-caption": "Author",
    alt: name
  };

  let imageView = null;

  if (avatarURL) {
    imageView = <ResponsiveImage slug={storyObj["hero-image-s3-key"]} aspectRatio={[1, 1]} alt={name} />;
  } else if (authorAvatarUrl) {
    imageView = <img src={authorAvatarUrl} alt={name} />;
  } else {
    imageView = <img src={`${assetify(authorDefault)}`} alt={author.name} />;
  }

  return (
    <div styleName="author-page-wrapper">
      {Object.keys(author).length === 0 ? (
        <Headline text="Author not found !" headerType={1} headerLevel={2} />
      ) : (
        <div>
          <div styleName="wrapper">
            <div styleName="picture-wrapper">{imageView}</div>
            <div styleName="info">
              <h1 styleName="name">
                {author.name}
                {author["twitter-handle"] && (
                  <a styleName="icon" href={`https://www.twitter.com/${author["twitter-handle"]}`}>
                    <TwitterIcon width="24" height="24" />
                  </a>
                )}
              </h1>
              <div styleName="detail" dangerouslySetInnerHTML={{ __html: author.bio }} />
            </div>
          </div>
        </div>
      )}
      <div>
        <LoadMoreAuthorStories
          template={MoreCollectionStories}
          authorID={author.id}
          data={{ collection: null, stories: data.stories, config: config }}
          numStoriesToLoad={4}
          params={{ "item-type": "story" }}
        />
      </div>
    </div>
  );
};

const getStory = stories => {
  return stories.map((story, index) => (
    <CarouselCard key={index} story={story} headerType={3} headerLevel={2} sectionTitle={true} />
  ));
};

const MoreCollectionStories = ({ collection, stories, onLoadMore, noMoreStories, config }) => {
  return (
    <div>
      <div styleName="stories">{getStory(stories)}</div>
      <div styleName="more-wrapper">
        {!noMoreStories && stories.length >= 8 ? <More type="load-more" onClick={onLoadMore} /> : null}
      </div>
    </div>
  );
};

AuthorPage.propTypes = {
  data: object,
  config: object,
  story: object
};

MoreCollectionStories.propTypes = {
  stories: object,
  collection: object,
  onLoadMore: func,
  noMoreStories: string,
  config: object
};

export { AuthorPage };
