/* eslint-disable jsx-a11y/img-redundant-alt, import/extensions */
import React from "react";

import { getCollectionTemplate } from "../get-collection-template";
import { LazyCollection, LazyLoadImages } from "@quintype/components";
import Headline from "../atoms/headline/index";
import { object } from "prop-types";
import "./section.m.css";

const getCollectionMeta = data => (
  <div styleName="collection-meta">
    <Headline text={data.collection.name || ""} headerType={10} headerLevel={2} />
    <p styleName="collection-summary">{data.collection.summary || ""}</p>
  </div>
);

export const SectionPage = ({ data }) => {
  const { collection } = data;
  collection.items = collection.items.filter(item => item.type === "collection");

  return (
    <div>
      {getCollectionMeta(data)}
      <LazyLoadImages>
        <LazyCollection collection={collection} collectionTemplates={getCollectionTemplate} lazyAfter={2} />
      </LazyLoadImages>
    </div>
  );
};

SectionPage.propTypes = {
  data: object
};
