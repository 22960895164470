import React from "react";
import { string } from "prop-types";
import "./section-name.m.css";

const SectionName = ({ name }) => {
  return (
    <div styleName="text" className="text">
      {name}
    </div>
  );
};

SectionName.propTypes = {
  name: string
};

export default SectionName;
