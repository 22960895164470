import React from "react";
import get from "lodash/get";
import { Link } from "@quintype/components";

import AuthorImage from "../../atoms/author-image";
import AuthorName from "../../atoms/author-name";
import Headline from "../../atoms/headline";
import "./opinion-card-without-hero.m.css";
import { object } from "prop-types";

const OpinionCardWithoutHero = ({ story = {} }) => {
  const authorDetails = {
    id: get(story, ["author-id"], ""),
    name: get(story, ["authors", 0, "name"], story["author-name"]),
    "avatar-url": get(story, ["authors", 0, "avatar-url"], ""),
    slug: get(story, ["authors", 0, "slug"], ""),
    "avatar-s3-key": get(story, ["authors", 0, "avatar-s3-key"])
  };

  return (
    <div styleName="wrapper">
      <Link href={`/author/${authorDetails.id}`}>
        <AuthorImage author={authorDetails} />
      </Link>
      <div>
        <Link href={`/author/${authorDetails.id}`}>
          <AuthorName author={authorDetails} fontSize="base" />
        </Link>
        <Link href={`/${story.slug}`}>
          <Headline text={story.headline} headerType={2} headerLevel={2} />
        </Link>
      </div>
    </div>
  );
};

OpinionCardWithoutHero.propTypes = {
  story: object
};

export default OpinionCardWithoutHero;
