import React from "react";
import { object, oneOf } from "prop-types";
import "./author-name.m.css";

const AuthorName = ({ author, fontSize = "large" }) => {
  return (
    <span styleName={`author-name-${fontSize}`} className="author-name">
      {author.name}
    </span>
  );
};

AuthorName.propTypes = {
  author: object,
  fontSize: oneOf(["large", "base"]).isRequired
};

export default AuthorName;
