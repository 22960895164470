import React from "react";
import { object } from "prop-types";
import { Link } from "@quintype/components";

import CardImage from "../../atoms/card-image/index";
import Headline from "../../atoms/headline/index";
import Video from "../../icons/video";
import "./tv-program-cover-image-with-ad.m.css";
import DfpAd from "../../dfp-ad";

const TvProgramCoverImageWithAd = ({ collection }) => {
  // Take only 1st story
  const [firstStory] = collection.items
    .filter(item => item.type === "story" && item.story["story-template"] === "video")
    .slice(0, 1);

  // Return back if 1st story itself doesn't exist
  if (!firstStory) return null;

  const { story } = firstStory;

  return (
    <div className="container">
      <div styleName="wrapper">
        {/* Single Story */}
        <Link href={`/${story.slug}`} styleName="story-content">
          <CardImage story={story} aspectRatio={[16, 9]} defaultWidth={330} />
          <div styleName="video-content">
            <div styleName="items">
              <Video />
              <Headline text={story.headline} headerType={8} headerLevel={2} />
            </div>
          </div>
        </Link>

        {/* Advertisements */}
        <div styleName="ad-slot">
          <DfpAd adtype="mrec" />
          <a href="https://rebrand.ly/seithigalytlive" target="_blank" rel="noopener noreferrer">
            <img
              data-src="https://gumlet.assettype.com/kalaignarseithigal/2019-04/5509afdd-d19b-4203-a5d1-c6900ddf144a/live_banner_web.jpg"  // eslint-disable-line
              alt="banner"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

TvProgramCoverImageWithAd.propTypes = {
  collection: object.isRequired
};

TvProgramCoverImageWithAd.storyLimit = 12;

export default TvProgramCoverImageWithAd;
