import React from "react";
import { object, bool } from "prop-types";
import Headline from "../../atoms/headline";
import get from "lodash/get";
import { getDateFromUnixTimestamp } from "../../helper/utils";
import "./author-with-date.m.css";

const AuthorWithDate = ({ story = {}, isHeadline = false }) => {
  const authorName = get(story, ["authors", 0, "name"], story["author-name"]);
  const publishedAt = getDateFromUnixTimestamp(story["last-published-at"]);

  return (
    <div styleName="wrapper" className="author-date-wrapper">
      {isHeadline && <Headline text={authorName} headerType={3} headerLevel={1} />}
      <time styleName="time">{publishedAt}</time>
    </div>
  );
};

AuthorWithDate.propTypes = {
  story: object,
  isHeadline: bool
};

export default AuthorWithDate;
