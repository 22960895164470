import React from "react";
import { object, string, array, bool, func, number } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import { LoadMoreCollectionStories } from "@quintype/components";
import Headline from "../../atoms/headline";
import More from "../../atoms/more";
import HorizontalContentWithImage from "../../molecules/horizontal-content-with-image";
import "./six-row-with-ad.m.css";
import DfpAd from "../../dfp-ad";

const SixRowWithAd = ({ collection, pageType }) => {
  // Reject if stories length is 0
  if (!collection.items.length) {
    return null;
  }

  return (
    <div className="container" styleName="container">
      {pageType !== "section-page" && <Headline text={collection.name} headerType={8} headerLevel={1} />}
      <div styleName="wrapper">
        <LoadMore collection={collection} pageType={pageType} />
        <div styleName="ad-slot">
          <DfpAd adtype="mrec_3" />
        </div>
      </div>
    </div>
  );
};

function MoreCollectionStories(params, pageType, numStoriesToLoad) {
  const { collection, stories, onLoadMore, noMoreStories } = params;

  const MoreComponent =
    pageType !== "section-page" ? (
      <More type="read-more" collection={collection} />
    ) : (
      <More type="load-more" onClick={onLoadMore} />
    );

  return (
    <div styleName="items-wrapper">
      {stories.map((item, index) => (
        <HorizontalContentWithImage key={index} story={item} />
      ))}
      {numStoriesToLoad <= stories.length && !noMoreStories && MoreComponent}
    </div>
  );
}

MoreCollectionStories.propTypes = {
  collection: object,
  stories: array,
  loading: bool,
  onLoadMore: func,
  noMoreStories: number,
  pageType: string
};

const LoadMore = ({ collection, numStoriesToLoad = 6, pageType }) => {
  const stories = collection.items.filter(item => item.type === "story");

  return (
    <React.Fragment>
      {stories && stories.length ? (
        <LoadMoreCollectionStories
          template={e => MoreCollectionStories(e, pageType, numStoriesToLoad)}
          collectionSlug={collection.slug}
          data={Object.assign(
            {},
            { collection },
            { stories: [...stories.map(({ story }) => story).slice(0, numStoriesToLoad)] },
            { storiesPerPage: numStoriesToLoad }
          )}
          numStoriesToLoad={numStoriesToLoad}
          params={{ "item-type": "story" }}
        />
      ) : null}
    </React.Fragment>
  );
};

LoadMore.propTypes = {
  collection: object.isRequired,
  numStoriesToLoad: number,
  pageType: string
};

function mapStateToProps(state) {
  return {
    pageType: get(state, ["qt", "pageType"], "")
  };
}

SixRowWithAd.defaultProps = {
  collection: {}
};

SixRowWithAd.propTypes = {
  collection: object.isRequired,
  pageType: string
};

SixRowWithAd.storyLimit = 12;

export default connect(mapStateToProps)(SixRowWithAd);
