import React from "react";
import { object, func, oneOf } from "prop-types";
import { Link } from "@quintype/components";
import "./more.m.css";

const More = ({ collection, type, onClick }) => {
  if (type === "read-more") {
    const slug = collection.template === "section" ? `/${collection.slug}` : `/collection/${collection.slug}`;

    return (
      <Link styleName="wrapper" className="more" href={slug}>
        <span styleName="content">Read More</span>
      </Link>
    );
  } else {
    return (
      <div styleName="wrapper" className="more" onClick={onClick}>
        <span styleName="content">Load More</span>
      </div>
    );
  }
};

More.defaultProps = {
  type: "read-more"
};

More.propTypes = {
  collection: object,
  onClick: func,
  type: oneOf(["read-more", "load-more"])
};

export default More;
