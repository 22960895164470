/* eslint-disable jsx-a11y/img-redundant-alt, import/extensions */
import React from "react";
import { object, shape } from "prop-types";

import { getCollectionTemplate } from "../get-collection-template";
import { LazyCollection, WithPreview, replaceAllStoriesInCollection, LazyLoadImages } from "@quintype/components";

export const HomePage = ({ data }) => {
  const { collection } = data;
  collection.items = collection.items.filter(item => item.type === "collection");

  return (
    <div>
      <LazyLoadImages>
        <LazyCollection collection={collection} collectionTemplates={getCollectionTemplate} lazyAfter={2} />
      </LazyLoadImages>
    </div>
  );
};

HomePage.propTypes = {
  data: shape({
    collection: object
  })
};

export const HomePagePreview = WithPreview(HomePage, (data, story) =>
  Object.assign({}, data, {
    collection: replaceAllStoriesInCollection(data.collection, story)
  })
);
