import React from "react";
import { object, bool } from "prop-types";
import Carousel from "../carousel";
import Headline from "../../atoms/headline";
import CarouselCard from "../story-card-5";
import "./carousel-slider.m.css";

const CarouselSlider = ({ collection }) => {
  const children = collection.items
    .filter(item => item.type === "story")
    .map((story, key) => <CarouselCard key={key} story={story.story} />);

  return (
    <div styleName="carousel-container" className="carousel-container">
      <Headline text={collection.name} headerType={5} headerLevel={2} />

      <Carousel
        carouselName="carousel"
        options={{
          type: "carousel",
          perView: 4,
          breakpoints: {
            480: { perView: 1, peek: { before: 0, after: 80 } }
          },
          peek: { before: 0, after: 50 }
        }}
        totalItems={collection.items.length}
        renderCenterLeftControls={({ previousSlide, currentSlide }) => {
          return (
            currentSlide !== 0 && (
              <button onClick={previousSlide} className="slider-btn">
                <div className="arrow left" />
              </button>
            )
          );
        }}
        renderCenterRightControls={({ nextSlide }) => (
          <button onClick={nextSlide} className="slider-btn">
            <div className="arrow right" />
          </button>
        )}
      >
        {children}
      </Carousel>
    </div>
  );
};

CarouselSlider.propTypes = {
  collection: object,
  sectionTitle: bool
};

export default CarouselSlider;
