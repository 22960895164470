import React from "react";
import { number } from "prop-types";

const TwitterIcon = ({ width = 24, height = 24 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#1CB7EB"
        fillRule="nonzero"
        d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0z"
      />
      <path
        fill="#FFF"
        d="M11.886 10.66l.026.433-.438-.053c-1.594-.203-2.987-.892-4.169-2.048l-.578-.574-.149.424c-.315.944-.114 1.942.543 2.613.35.37.272.423-.333.203-.21-.071-.394-.124-.411-.097-.062.061.149.865.315 1.182.228.442.692.874 1.2 1.13l.429.203-.508.01c-.49 0-.508.008-.455.193.175.574.867 1.183 1.637 1.448l.543.185-.472.283c-.701.406-1.524.635-2.348.653-.394.009-.718.044-.718.07 0 .089 1.069.583 1.69.777 1.866.574 4.082.327 5.746-.653 1.182-.697 2.365-2.083 2.917-3.425.297-.715.595-2.021.595-2.648 0-.406.027-.459.517-.944.289-.283.56-.592.613-.68.088-.168.079-.168-.368-.018-.744.265-.85.23-.481-.167.271-.283.595-.795.595-.945 0-.026-.131.018-.28.097-.158.088-.508.22-.77.3l-.474.15-.43-.291c-.236-.159-.568-.335-.744-.388-.446-.124-1.13-.106-1.532.035-1.095.397-1.787 1.421-1.708 2.542z"
      />
    </g>
  </svg>
);

TwitterIcon.propTypes = {
  width: number,
  height: number
};

export default TwitterIcon;
