import React from "react";
import { array, string, object } from "prop-types";
import StoryCard3 from "../../molecules/story-card-3";
import Headline from "../../atoms/headline";
import CarouselSlider from "../../molecules/carousel-slider";
import "./photo-story.m.css";
import get from "lodash/get";

export const PhotoStory = ({ collection }) => {
  let stories = get(collection, ["items"], [])
    .filter(item => item.type === "story")
    .slice(0, 4);

  const photoStoryStack =
    stories.length === 4 &&
    stories.map((story, key) => {
      const storyTemplate = story.story["story-template"];
      const imageType = key => (key === 1 ? "image2x1" : "image1x1");
      const getHeaderType = key => {
        switch (key) {
          case 0:
            return 5;
          case 1:
            return 2;
          case 2:
            return 3;
          case 3:
            return 3;
          default:
            break;
        }
      };

      return (
        storyTemplate === "photo" && (
          <StoryCard3
            story={story.story}
            headerLevel={2}
            headerType={getHeaderType(key)}
            key={key}
            imageType={imageType(key)}
          />
        )
      );
    });

  return (
    <div styleName="container">
      <div styleName="wrapper">
        <Headline text={collection.name} headerType={1} headerLevel={3} />
        <div styleName="photo-story-wrapper">{photoStoryStack}</div>
      </div>
      <div styleName="wrapper-slider">
        <CarouselSlider collection={collection} />
      </div>
    </div>
  );
};

PhotoStory.propTypes = {
  stories: array,
  collection: object,
  collectionName: string
};

PhotoStory.storyLimit = 12;

export default PhotoStory;
