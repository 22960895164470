import React from "react";
import { object } from "prop-types";
import VerticalContentWithImage from "../../molecules/vertical-content-with-image";
import StoryCard1 from "../../molecules/story-card-1";
import "./cover-image-three-col-with-ad.m.css";
import DfpAd from "../../dfp-ad";

const CoverImageThreeColWithAd = ({ collection }) => {
  const [firstStory, ...restOftheStories] = collection.items.filter(item => item.type === "story").slice(0, 4);

  if (!firstStory) {
    return null;
  }

  return (
    <div className="container">
      <div styleName="wrapper">
        <div styleName="story-with-ad">
          <StoryCard1 story={firstStory.story} headerType={1} headerLevel={2} />
          <div styleName="ad-slot">
            <a href="https://rebrand.ly/seithigalytlive" target="_blank" rel="noopener noreferrer">
              <img
                data-src="https://gumlet.assettype.com/kalaignarseithigal/2019-04/5509afdd-d19b-4203-a5d1-c6900ddf144a/live_banner_web.jpg"
                alt="banner"
              />
            </a>
            <DfpAd adtype="mrec" />
          </div>
        </div>
        <div styleName="items-wrapper">
          {restOftheStories.map((item, index) => (
            <VerticalContentWithImage key={index} story={item.story} />
          ))}
        </div>
      </div>
    </div>
  );
};

CoverImageThreeColWithAd.propTypes = {
  collection: object.isRequired
};

CoverImageThreeColWithAd.storyLimit = 12;

export default CoverImageThreeColWithAd;
