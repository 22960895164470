import React from "react";
import PT from "prop-types";
import isEqual from "lodash/isEqual";
import { ResponsiveSource, ResponsiveImage } from "@quintype/components";
import "./responsive-source-image.m.css";

const ResponsiveSourceImage = ({ story, desktopAspectRation, mobileAspectRation }) => {
  const getAspectRatioStyleName = (desktopRatio, mobileRatio) => {
    if (isEqual(desktopRatio, [16, 9]) && isEqual(mobileRatio, [4, 3])) {
      return "responsive-imagex1";
    } else if (isEqual(desktopRatio, [1, 1]) && isEqual(mobileRatio, [4, 3])) {
      return "responsive-imagex2";
    }
  };

  return (
    <figure styleName={getAspectRatioStyleName(desktopAspectRation, mobileAspectRation)} className="responsive-image">
      <picture styleName="picture">
        {/* Desktop Version */}
        <ResponsiveSource
          media="(min-width: 1024px)"
          slug={story["hero-image-s3-key"]}
          metadata={story["hero-image-metadata"]}
          aspectRatio={desktopAspectRation}
          styleName="image"
          imgParams={{ auto: ["format", "compress"] }}
          alt={story["hero-image-caption"] || story.headline}
        />

        {/* Mobile Version */}
        <ResponsiveImage
          slug={story["hero-image-s3-key"]}
          metadata={story["hero-image-metadata"]}
          alt={story["hero-image-caption"] || story.headline}
          aspectRatio={mobileAspectRation}
          styleName="image"
          imgParams={{ auto: ["format", "compress"] }}
        />
      </picture>
    </figure>
  );
};

ResponsiveSourceImage.defaultProps = {
  desktopAspectRation: [16, 9],
  mobileAspectRation: [4, 3]
};

ResponsiveSourceImage.propTypes = {
  story: PT.object,
  desktopAspectRation: PT.array,
  mobileAspectRation: PT.array
};

export default ResponsiveSourceImage;
