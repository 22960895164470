import React from "react";
import { object, number } from "prop-types";
import CarouselCard from "../../molecules/story-card-5";
import More from "../../atoms/more";

import "./load-more-search-results.m.css";

class LoadMoreSearchResults extends React.Component {
  constructor(props) {
    super(props);
    const { total, stories, query } = props.data;

    this.state = {
      stories: stories,
      totalNumberOfStories: total,
      offset: stories.length,
      query: query,
      itemsToLoad: props.itemsToLoad || 4,
      noMoreStories: stories.length >= total
    };
  }

  loadMore = () => {
    const { itemsToLoad, offset, stories, query } = this.state;

    return global
      .wretch("/api/v1/search")
      .query({
        offset: offset,
        limit: itemsToLoad,
        q: query
      })
      .get()
      .json(response => {
        const updatedResults = [].concat(stories, response.results.stories);
        this.setState({
          stories: updatedResults,
          offset: offset + itemsToLoad,
          noMoreStories: updatedResults.length >= this.state.totalNumberOfStories
        });
      });
  };

  getStory = stories => {
    return stories.map((story, index) => (
      <CarouselCard key={index} story={story} headerType={3} headerLevel={2} sectionTitle={true} />
    ));
  };

  render() {
    const { stories, noMoreStories } = this.state;

    return (
      <div>
        <div styleName="stories">{stories.length ? this.getStory(stories) : null}</div>
        <div styleName="more-wrapper">{!noMoreStories && <More type="load-more" onClick={this.loadMore} />}</div>
      </div>
    );
  }
}

LoadMoreSearchResults.propTypes = {
  data: object,
  itemsToLoad: number
};

export default LoadMoreSearchResults;
